import React from 'react';
import { Row, Col, Image, Form, InputGroup } from 'react-bootstrap';
import { Field, Formik, Form as FormikForm } from 'formik';
import RegistrationActionButton from '../../../components/RegistrationActionButton';
import logo from '../../../assets/images/job-portal/zestur-logo-internal.svg';
import { ASSETS_URL } from '../../../constants';
import { backgroundImage } from '../../../utils/AppConstants';
import { useDispatch, useSelector } from 'react-redux';
import Step from '../../../models/step.interface';
import { AppDispatch, RootState } from '../../../store/store';
import { object, string } from 'yup';
import { setCompanyAddress } from '../../../store/slices/companySlice';
import { useTranslation } from 'react-i18next';
import RestrictedInput from '../../../components/ui/restricted-input/RestrictedInput';
import ProgressBarTitle from '../../../components/ui/progress-bar-title/ProgressBarTitle';
import MobileProgress from '../../../components/mobile-progress/MobileProgress';

function RegistrationStep2(props: Step) {
  const { t } = useTranslation();
  const company = useSelector((state: RootState) => state.companyReducer);
  const dispatch = useDispatch<AppDispatch>();
  const logoURL: string = `${ASSETS_URL}`;

  const validationSchema = object({
    address: string().required(t('errors.fieldRequired')),
    addressNumber: string().required(t('errors.fieldRequired')),
    addressStair: string().required(t('errors.fieldRequired')),
    city: string().required(t('errors.fieldRequired')),
    country: string().required(t('errors.fieldRequired')),
  });

  return (
    <div className='bg-white register-form'>
      <Row className='bg-blue-gradient'>
        <Col xs={12} className='no-mobile-hidden'>
          <MobileProgress
            data={props}
            progressValue={40}
            startStep={2}
            endStep={5}
          />
        </Col>

        <Col xs={12} className='no-mobile-hidden'>
          <h2 className='text-main-black px-4'>
            {t('companyRegistration.step1.title')}{' '}
          </h2>
        </Col>

        <Col
          xs={12}
          md={9}
          lg={10}
          className='d-flex justify-content-center justify-items-center'
          style={{margin:"0 auto"}}
        >
          <Formik
            initialValues={{
              address: company.address,
              addressNumber: company.addressNumber,
              addressStair: company.addressStair,
              addressFloor: company.addressFloor,
              city: company.city,
              country: company.country,
            }}
            onSubmit={(values) => {
              dispatch(setCompanyAddress(values));
              props.handleNext();
            }}
            validationSchema={validationSchema}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <FormikForm className='form w-100 d-flex justify-content-evenly flex-column'>
                <div className='main-ProgressBar mobile-hidden'>
                  <ProgressBarTitle
                    startStep={2}
                    endStep={6}
                    progressValue={34}
                  />
                  <h2>{t('companyRegistration.step1.title')} </h2>
                </div>
                {/* form-fields */}
                <Row className='form-fields candidate'>
                  <Row>
                    <Form.Group
                      as={Col}
                      md={8}
                      xs={12}
                      controlId='validationCustom01'
                    >
                      <Form.Label className='label'>
                        {t('companyRegistration.step2.addressLabel')}
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Field
                          className='form-control mt-2 px-4 py-3'
                          type='text'
                          placeholder=''
                          aria-describedby='inputGroupPrepend'
                          name='address'
                        />
                        {errors.address && touched.address ? (
                          <p className='text-danger position-absolute error-message candidate'>
                            {errors.address}
                          </p>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md={4}
                      xs={12}
                      controlId='validationCustom02'
                      className='mobile-mt-2'
                    >
                      <Form.Label className='label'>
                        {t('companyRegistration.step2.addressNumberLabel')}
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Field>
                          {() => (
                            <RestrictedInput
                              setFieldValue={setFieldValue}
                              fieldValue={values.addressNumber}
                              valueName='addressNumber'
                              restriction='number'
                            />
                          )}
                        </Field>
                        {errors.addressNumber && touched.addressNumber ? (
                          <p className='text-danger position-absolute error-message candidate'>
                            {errors.addressNumber}
                          </p>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group
                      as={Col}
                      md={4}
                      xs={12}
                      controlId='validationCustom03'
                      className='mobile-mt-2'
                    >
                      <Form.Label className='label'>
                        {t('companyRegistration.step2.addressFloorLabel')}
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Field
                          className='form-control mt-2 px-4 py-3'
                          type='text'
                          placeholder=''
                          aria-describedby='inputGroupPrepend'
                          name='addressFloor'
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md={4}
                      xs={12}
                      controlId='validationCustom03'
                      className='mobile-mt-2'
                    >
                      <Form.Label className='label'>
                        {t('companyRegistration.step2.addressStairLabel')}
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Field
                          className='form-control mt-2 px-4 py-3'
                          type='text'
                          placeholder=''
                          aria-describedby='inputGroupPrepend'
                          name='addressStair'
                        />
                        {errors.addressStair && touched.addressStair ? (
                          <p className='text-danger position-absolute error-message candidate'>
                            {errors.addressStair}
                          </p>
                        ) : null}
                      </InputGroup>
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      md={4}
                      xs={12}
                      controlId='validationCustom01'
                      className='mobile-mt-2'
                    >
                      <Form.Label className='label'>
                        {t('companyRegistration.step2.cityLabel')}
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Field>
                          {() => (
                            <RestrictedInput
                              setFieldValue={setFieldValue}
                              fieldValue={values.city}
                              valueName='city'
                              restriction='text'
                            />
                          )}
                        </Field>
                        {errors.city && touched.city ? (
                          <p className='text-danger position-absolute error-message candidate'>
                            {errors.city}
                          </p>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group as={Col} md={4} xs={12} className='mobile-mt-2'>
                      <Form.Label className='label'>
                        {t('candidateRegistration.step1.regionLabel')}
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Field>
                          {() => (
                            <RestrictedInput
                              setFieldValue={setFieldValue}
                              fieldValue={values.country}
                              valueName='country'
                              restriction='text'
                            />
                          )}
                        </Field>
                        {errors.country && touched.country ? (
                          <p className='text-danger position-absolute error-message candidate'>
                            {errors.country}
                          </p>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                  </Row>
                </Row>
                <Row className='mt-4 no-mobile-hidden'></Row>
                <RegistrationActionButton
                  data={props}
                  isSubmit={true}
                ></RegistrationActionButton>
              </FormikForm>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
}

export default RegistrationStep2;
