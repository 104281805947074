import { AIMatchStatus } from '../models/ai-match-status';

export const defaultNotSelectedDate: string = '1900-01-01';
export const maxAvatarSize = 16;
export const maxLogoSize = 16;
export const maxCompanyCoverSize = 16;

export const linkedInCredentials = {
  clientId: '77eqwyvumyvj3b',
  clientSecret: '',
  redirectURI: `${window.location.origin}/linkedin`,
  accessTokenPath: 'https://www.linkedin.com/oauth/v2/accessToken',
};

export const matchStarted: AIMatchStatus = 'STARTED';
export const AIModels = {
  turbo: 'gpt-3.5-turbo',
  prevMain: 'gpt-4-turbo-preview',
  main: 'gpt-4o',
};

export const plans = {
  trial: {
    limit: 5,
    name: 'trial',
  },
  premium: {
    limit: 500,
    name: 'premium',
  },
};
