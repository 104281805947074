import React from 'react';
import { Row, Col, Image, Form, InputGroup } from 'react-bootstrap';
import { Field, Formik, Form as FormikForm } from 'formik';
import RegistrationActionButton from '../../../components/RegistrationActionButton';
import logo from '../../../assets/images/job-portal/zestur-logo-internal.svg';
import { ASSETS_URL } from '../../../constants';
import { backgroundImage } from '../../../utils/AppConstants';
import Step from '../../../models/step.interface';
import { object, string } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import {
  setBusinessName,
  setCompanyDescription,
  setNif,
  checkNif
} from '../../../store/slices/companySlice';
import { useTranslation } from 'react-i18next';
import { validateNif } from '../../../utils/validate-nif';
import ProgressBarTitle from '../../../components/ui/progress-bar-title/ProgressBarTitle';
import MobileProgress from '../../../components/mobile-progress/MobileProgress';

function RegistrationStep3(props: Step) {
  const { t } = useTranslation();
  const company = useSelector((state: RootState) => state.companyReducer);
  const dispatch = useDispatch<AppDispatch>();
  const logoURL: string = `${ASSETS_URL}`;
  const validationSchema = object({
    businessName: string().required(t('errors.fieldRequired')),
    nif: string()
      .required(t('errors.fieldRequired'))
      .test(
        'verifyIdentification',
        t('errors.verifyIdentification'),
        validateNif
      ).test('verifyNIF', t('errors.candidateNifExists'), function (value) {
                return new Promise((resolve, reject) => {
                  dispatch(checkNif(value))
                      .then((exists) => {
                        resolve(exists.payload == true) // false is an error, true means valid / does not exist
                    }).catch(() => {
                        resolve(true)
                    })
                })
            })
    ,
    description: string().required(t('errors.fieldRequired')),
  });

  return (
    <div className='bg-white register-form'>
      <Row className='bg-blue-gradient'>
        <Col xs={12} className='no-mobile-hidden'>
          <MobileProgress
            data={props}
            progressValue={60}
            startStep={3}
            endStep={5}
          />
        </Col>

        <Col xs={12} className='no-mobile-hidden'>
          <h2 className='text-main-black px-4'>
            {t('companyRegistration.step1.title')}{' '}
          </h2>
        </Col>

        <Col
          xs={12}
          md={9}
          lg={10}
          className='d-flex justify-content-center justify-items-center'
          style={{margin:"0 auto"}}
        >
          <Formik
            initialValues={{
              businessName: company.businessName,
              nif: company.nif,
              description: company.description,
            }}
            onSubmit={(values) => {
              dispatch(setBusinessName(values.businessName));
              dispatch(setNif(values.nif));
              dispatch(setCompanyDescription(values.description));
              props.handleNext();
            }}
            validationSchema={validationSchema}
          >
            {({ errors, touched }) => (
              <FormikForm className='form w-100 d-flex justify-content-evenly flex-column'>
                {/* main-ProgressBar */}
                <div className='main-ProgressBar mobile-hidden'>
                  <ProgressBarTitle
                    startStep={3}
                    endStep={6}
                    progressValue={50}
                  />
                  <h2>{t('companyRegistration.step1.title')} </h2>
                </div>
                {/* form-fields */}
                <Row className='form-fields candidate'>
                  <Row>
                    <Form.Group
                      as={Col}
                      md={6}
                      xs={12}
                      controlId='validationCustom01'
                    >
                      <Form.Label className='label'>
                        {t('companyRegistration.step3.bussinessNameLabel')}
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Field
                          className='form-control mt-2 px-4 py-3'
                          type='text'
                          placeholder=''
                          aria-describedby='inputGroupPrepend'
                          name='businessName'
                        />
                        {errors.businessName && touched.businessName ? (
                          <p className='text-danger position-absolute error-message candidate'>
                            {errors.businessName}
                          </p>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md={6}
                      xs={12}
                      controlId='validationCustom02'
                      className='mobile-mt-2'
                    >
                      <Form.Label className='label'>
                        {t('companyRegistration.step3.nifLabel')}
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Field
                          className='form-control mt-2 px-4 py-3'
                          type='text'
                          placeholder=''
                          aria-describedby='inputGroupPrepend'
                          name='nif'
                        />
                        {errors.nif && touched.nif ? (
                          <p className='text-danger position-absolute error-message candidate'>
                            {errors.nif}
                          </p>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} xs={12} className='mobile-mt-2'>
                      <Form.Label className='label'>
                        {t('companyRegistration.step3.descriptionLabel')}
                      </Form.Label>

                      <InputGroup hasValidation>
                        <Field
                          as='textarea'
                          name='description'
                          className='form-control form-outline mt-2 px-4 py-3 size'
                        />
                        {errors.description && touched.description ? (
                          <p className='text-danger position-absolute error-message candidate'>
                            {errors.description}
                          </p>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                  </Row>
                </Row>

                <Row className='mt-4 no-mobile-hidden'></Row>

                <RegistrationActionButton
                  data={props}
                  isSubmit={true}
                ></RegistrationActionButton>
              </FormikForm>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
}

export default RegistrationStep3;
