import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { useGoogleLogin } from '@react-oauth/google';
import React from 'react';
import tokenService from '../../_services/token.service';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { config } from '../../_config/config';
import facebookLogo from '../../assets/icons/facebook.svg';
import googleLogo from '../../assets/icons/google.svg';
import linkedinLogo from '../../assets/icons/linkedin.svg';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { linkedInCredentials } from '../../constants/constants';
import { decodeToken } from '../../models/decode-token';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/store';
import { setTokenExhausted } from '../../store/slices/authSlice';
import {
  setCandidateExistsError,
  setCandidateTokenExhausted,
} from '../../store/slices/candidateSlice';
import { setCompanyTokenExhausted } from '../../store/slices/companySlice';

const SocialIcons = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const googleLogin = useGoogleLogin({
    onSuccess: ({ access_token }) => {
      getToken(access_token, 'google');
    },
    onError: () => {
      signInProviderError();
    },
  });

  // const { linkedInLogin } = useLinkedIn({
  //   clientId: linkedInCredentials.clientId,
  //   scope: 'profile email openid',
  //   redirectUri: linkedInCredentials.redirectURI,
  //   onSuccess: async (code) => {
  //     const accessToken = await getLinkedInAccessToken(code);
  //     if (accessToken) {
  //       getToken(accessToken, 'linkedin');
  //     }
  //   },
  //   onError: (error) => {
  //     console.log(error);
  //   },
  // });

  const signInProviderError = () => {
    toast.error(t('errors.socialSigninError'));
  };

  // const userDoesNotExistError = () => {
  //   toast.error(t('errors.credentialError'));
  // };

  // const loadFB = async () => {
  //   FacebookLoginClient.clear();
  //   await FacebookLoginClient.loadSdk('en_US');
  //   FacebookLoginClient.init({ appId: 'appId', version: 'v16.0' });
  // };

  // // Due to CORS this request should be sent from server
  // const getLinkedInAccessToken = async (authCode: string) => {
  //   try {
  //     const response = await axios.post(
  //       linkedInCredentials.accessTokenPath,
  //       {
  //         grant_type: 'authorization_code',
  //         code: authCode,
  //         client_id: linkedInCredentials.clientId,
  //         client_secret: linkedInCredentials.clientSecret,
  //         redirect_uri: linkedInCredentials.redirectURI,
  //       },
  //       {
  //         headers: {
  //           'Content-Type': 'application/x-www-form-urlencoded',
  //         },
  //       }
  //     );

  //     return response.data.access_token;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getToken = async (oauthToken: string, type: 'google' | 'linkedin') => {
    try {
      const response = await axios.post(`${config.SERVER_URL}token/o/auth/`, {
        access_token: oauthToken,
        type: type,
      });

      tokenService.setUser({
        access: response.data.access,
        refresh: response.data.refresh,
      });

      navigateOnTokenType(response.data.access);
    } catch (error) {
      toast.error(t('messages.credentialError'));
      dispatch(setTokenExhausted(false));
      dispatch(setCandidateExistsError(false));
      dispatch(setCompanyTokenExhausted(false));
      dispatch(setCandidateTokenExhausted(false));
    }
  };

  const navigateOnTokenType = (token: string) => {
    const decodedToken = decodeToken(token);
    if (decodedToken?.candidate_id) navigate('/candidate');
    if (decodedToken?.company_id) navigate('/company');
  };

  // useEffect(() => {
  //   loadFB();
  // }, []);

  return (
    <div className='d-flex justify-content-evenly align-items-center social-icons'>
      {/* <img
        src={facebookLogo}
        alt='facebook'
        className='cursor-pointer'
        // onClick={() =>
        //   FacebookLoginClient.login(console.log, {
        //     scope: 'public_profile, email',
        //   })
        // }
      /> */}
      <a className='social-login-button' onClick={() => googleLogin()}>
        <img
          src={googleLogo}
          alt='google'
          className='cursor-pointer'
        /> {t('auth.googleLogin')}
      </a>
      {/* <img
        src={linkedinLogo}
        alt='linkedIn'
        className='cursor-pointer'
        onClick={linkedInLogin}
      /> */}
    </div>
  );
};

export default SocialIcons;
