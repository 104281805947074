import React from 'react';
import eyeOpen from '../../../assets/icons/eye-open-white.svg';
import eyeClose from '../../../assets/icons/eye-white-closed.svg';
import './EyeIcon.scss';

interface Props {
  top?: number;
  right?: number;
  className?: string;
  isOpen: boolean;
  action: () => void;
}

const EyeIcon = ({
  action,
  isOpen,
  top = 19,
  right = 12,
  className = '',
}: Props) => {
  return (
    <img
      width={24}
      height={24}
      style={{ top, right }}
      src={isOpen ? eyeOpen : eyeClose}
      alt='show'
      className={`eye-icon cursor-pointer blue-filter ${className}`}
      onClick={action}
    />
  );
};

export default EyeIcon;
