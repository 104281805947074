import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import Step from '../models/step.interface';
import { useTranslation } from 'react-i18next';

interface DataStep {
  data: Step;
  isSubmit?: boolean;
  submitDisabled?: boolean;
}

const RegistrationActionButton = (props: DataStep) => {
  const { t } = useTranslation();
  const [stateVariable] = useState(props.data);
  return (
    <>
      <div className='buttons justify-items-center align-items-center'>
        {stateVariable.currentStep > 0 && (
          <Button
            className='btn1 me-2'
            type='button'
            onClick={stateVariable.handlePrev}
          >
            {t('registrationForm.steps.actions.returnButton')}
          </Button>
        )}

        {stateVariable.currentStep < stateVariable.steps - 1 &&
          !props.isSubmit && (
            <Button
              className='btn2 ms-2 px-4 py-3'
              type='button'
              onClick={stateVariable.handleNext}
              disabled={props.submitDisabled}
            >
              {t('registrationForm.steps.actions.submitButton')}
            </Button>
          )}

        {/* Submit type button */}
        {stateVariable.currentStep < stateVariable.steps - 1 &&
          props.isSubmit && (
            <Button
              className='btn2 ms-2 px-4 py-3'
              type='submit'
              disabled={props.submitDisabled}
            >
              {t('registrationForm.steps.actions.submitButton')}
            </Button>
          )}
      </div>
      {stateVariable.currentStep === stateVariable.steps - 1 && (
        <Button type='submit'>
          {t('registrationForm.steps.actions.finishButton')}
        </Button>
      )}
    </>
  );
};

export default RegistrationActionButton;
