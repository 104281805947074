import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Image } from 'react-bootstrap';

import grid from '../../../assets/icons/home.svg';
import logo from '../../../assets/images/job-portal/zestur-logo-internal.svg';
import activity from '../../../assets/icons/blog.svg';
import layers from '../../../assets/icons/layers.svg';
import chat from '../../../assets/icons/message.svg';
import logout from '../../../assets/icons/logout.svg';
import './CompanySidebar.scss';
import { ASSETS_URL } from '../../../constants';
import tokenService from '../../../_services/token.service';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store/store';
import { resetCompanyData } from '../../../store/slices/companySlice';

const CompanySidebar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const logoURL: string = `${ASSETS_URL}`;

  const handleLogout = () => {
    if (tokenService.getUser()) {
      tokenService.removeUser();
      dispatch(resetCompanyData());
      navigate('/');
    }
  };

  const links = [
    {
      to: '/company',
      icon: grid,
      text: t('menu.company.home'),
    },
    {
      to: '/company/applicants',
      icon: layers,
      text: t('menu.company.info'),
    },
    {
      to: '/company/chat',
      icon: chat,
      text: t('menu.messages'),
    },
    {
      to: '/company/vacancies',
      icon: activity,
      text: t('menu.company.vacancies'),
    },
  ];

  return (
    <>
      <div className={`zestur-sidebar mobile-hidden`}>
        <div className='zestur-sidebar__header'>
          <Image
            className='cursor-pointer'
            src={logoURL + logo}
            alt='logo Image'
            fluid
            onClick={() => navigate('/company')}
          />
        </div>
        <div className='zestur-sidebar__links'>
          {links.map((link, index) => (
            <NavLink
              end
              to={link.to}
              className='zestur-sidebar__links-item'
              key={index}
            >
              <div className='icon-container'>
                <Image src={logoURL + link.icon} />
              </div>
              <span className='item-text'>{link.text}</span>
            </NavLink>
          ))}

          <div className='d-flex align-items-center justify-content-center'>
            <button
              type='button'
              onClick={() => {
                navigate('/company/offers');
              }}
              className='btn px-4 btn-outline-primary btn-outline-color'
            >
              {t('menu.company.createOffer')}
            </button>
          </div>
          <button
            onClick={() => handleLogout()}
            type='button'
            className='zestur-sidebar__links-item logout-btn'
          >
            <div className='icon-container'>
              <Image src={logoURL + logout} />
            </div>
            <span className='item-text'>{t('menu.logout')}</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default CompanySidebar;
