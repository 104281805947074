import React, { useState } from 'react';
import { GoogleOAuthProvider, GoogleLogin, CredentialResponse } from "@react-oauth/google";
import { useDispatch } from 'react-redux';
import { Col, Row, InputGroup, Form as RbForm, Button } from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { login } from '../../../../actions/auth';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { AppDispatch } from '../../../../store/store';

const clientId = "172631265102-irtrp8e9kkqm0814viivu12vf6mupvee.apps.googleusercontent.com";

const LoginFormCandidate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email(t('errors.emailInvalid'))
        .required(t('errors.emailRequired')),
    password: Yup.string().required(t('errors.passwordRequired')),
  });

  const initialValues = { email: '', password: '' };

  const handleGoogleSuccess = (response: CredentialResponse) => {
    console.log("Google SSO Success:", response);
    toast.success(t('auth.googleLoginSuccess'));
  };

  const handleGoogleError = () => {
    console.error("Google SSO Failed");
    toast.error(t('auth.googleLoginError'));
  };

  return (
      <GoogleOAuthProvider clientId={clientId}>
        <div className='bg-image login h-100 w-100 d-flex justify-content-center align-items-center'>
          <div className='main'>
            <h2 className='register text-center'>{t('loginForm.title')}</h2>
            <p className='confrom text-center'>
              {t('loginForm.subtitle')}
              <span className='cursor-pointer' onClick={() => navigate('/auth/candidate/sign-up')}>
              {t('loginForm.registrationUrl')}
            </span>
            </p>

            {/* Normal Login (Email & Password) */}
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values, { setSubmitting }) => {
              dispatch(login(values.email, values.password));
              setSubmitting(false);
            }}>
              {({ isValid }) => (
                  <Form className='form'>
                    <Row className='mt-2 text-start'>
                      <RbForm.Group as={Col} xs={12} controlId='validationCustom01'>
                        <RbForm.Label className='input-label'>{t('loginForm.emailLabel')}</RbForm.Label>
                        <InputGroup>
                          <Field className='form-control auth-input' type='text' placeholder={t('loginForm.emailPlaceholder')} name='email' />
                        </InputGroup>
                        <ErrorMessage name='email' component='p' className='text-danger position-absolute error-message' />
                      </RbForm.Group>

                      <RbForm.Group as={Col} xs={12} controlId='validationCustom02'>
                        <RbForm.Label className='input-label'>{t('loginForm.passwordLabel')}</RbForm.Label>
                        <InputGroup hasValidation>
                          <Field className='form-control auth-input' type={showPassword ? 'text' : 'password'} placeholder={t('loginForm.passwordPlaceholder')} name='password' autoComplete='on' />
                          {/* 🔥 Ajustamos el botón del ojo para que no tenga fondo ni borde */}
                          <button
                              type="button"
                              aria-label="Toggle password visibility"
                              onClick={() => setShowPassword((prev) => !prev)}
                              style={{
                                background: 'transparent',
                                border: 'none',
                                cursor: 'pointer'
                              }}
                          >
                            👁
                          </button>
                        </InputGroup>
                        <ErrorMessage name='password' component='p' className='text-danger position-absolute error-message' />
                      </RbForm.Group>
                    </Row>

                    <Button disabled={!isValid} className='w-100 primary-button p-3' type='submit'>
                      {t('auth.loginButton')}
                    </Button>
                  </Form>
              )}
            </Formik>

            {/* OR Divider */}
            <div className="text-center my-3">
              <span>──────── {t('loginForm.or')} ────────</span>
            </div>

            {/* ✅ Google SSO Button BELOW Email/Password Login with bigger text and Spanish */}
            <div className="google-login-container text-center">
              <div style={{ fontSize: '16px', fontWeight: 'bold' }}> {/* Aplicamos estilos aquí */}
                <GoogleLogin
                    onSuccess={handleGoogleSuccess}
                    onError={handleGoogleError}
                    theme="outline"
                    size="large"
                    text="signin_with"
                    shape="pill"
                    width="100%"
                    locale="es"
                />
              </div>
            </div>
          </div>
        </div>
      </GoogleOAuthProvider>
  );
};

export default LoginFormCandidate;