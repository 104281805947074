import React, {useState} from 'react';
import { Col, Row, InputGroup, Form as RbForm, Button } from 'react-bootstrap';
import SideContentFeature from '../Auth/SideContentFeature/SideContentFeature';
import { Image } from 'react-bootstrap';
import logo from '../../assets/images/job-portal/zestur-logo-internal.svg';
import contentLifecycle from '../../assets/icons/content-lifecycle.svg';
import sheet from '../../assets/icons/sheet.svg';
import eye from '../../assets/icons/eye.svg';
import { useNavigate } from 'react-router-dom';
import { ASSETS_URL } from '../../constants';
import './Home.scss';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../../components/language-selector/LanguageSelector';
import {setFormView} from "../../store/slices/authPageSlice";
import back from "../../assets/icons/back.svg";
import {ErrorMessage, Field, Form, Formik} from "formik";
import EyeIcon from "../../components/ui/eye-icon/EyeIconWhite";
import ForgotPasswordForm from "../../components/forgot-password-form/ForgotPasswordForm";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../store/store";
import * as Yup from "yup";
import {LoginForm} from "../../models/login-form,interface";
import {login} from "../../actions/auth";
import {setCompanyExistsError, setCompanyTokenExhausted} from "../../store/slices/companySlice";
import {
  applyToQROffer,
  setCandidateExistsError,
  setCandidateTokenExhausted,
} from '../../store/slices/candidateSlice';
import {checkIfSuperuserToken} from "../../utils/check-if-superadmin-token";
import {checkIfCompanyToken} from "../../utils/check-if-company-token";
import {toast} from "react-toastify";
import {resetPassword} from "../../store/slices/resetSlice";
import {backgroundImage} from "../../utils/AppConstants";
import { GoogleOAuthProvider, GoogleLogin, CredentialResponse } from "@react-oauth/google";
import {checkIfCandidateToken} from "../../utils/check-if-candidate-token";
import SocialIcons from "../Auth/SocialIcons";

interface InfoSection {
  title: string;
  description: string;
}

const images = [contentLifecycle, sheet, eye];
const alts = ['content lifecycle', 'sheet', 'eye'];

const HomeComponent = () => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const toolkitDispatch = useDispatch<AppDispatch>();
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const initialValues = { email: '', password: '' };

  const validationSchema = Yup.object().shape({
      email: Yup.string()
        .email(t('errors.emailInvalid'))
        .required(t('errors.emailRequired')),
      password: Yup.string().required(t('errors.passwordRequired')),
    });

    const handleSubmit = async (
      values: LoginForm,
      setSubmitting: (isSubmitting: boolean) => void
    ) => {
      try {
        const getTokenResponse = await dispatch(
          login(values.email, values.password)
        );

        if (!getTokenResponse.access && !getTokenResponse.refresh) return;

        if (checkIfSuperuserToken()) {
          navigate('/zasadmin/');
        } else if (checkIfCompanyToken()) {
          toolkitDispatch(setCompanyTokenExhausted(false));
          dispatch(setCompanyExistsError(false));
          // Push the event to dataLayer upon success
          if (window.dataLayer) {
            window.dataLayer.push({
              'event': 'SignIn'
            });
          }
          navigate('/company');
        } else if (checkIfCandidateToken()) {
          toolkitDispatch(setCandidateTokenExhausted(false));
          dispatch(setCandidateExistsError(false));
          if (window.dataLayer) {
            window.dataLayer.push({
              'event': 'SignIn'
            });
          }

          const searchedCompany = sessionStorage.getItem('searchedCompany');
          if (searchedCompany) {
            dispatch(applyToQROffer(searchedCompany));
          }

          navigate('/candidate');
        } else {
          dispatch(setCompanyExistsError(true));
          toast.error(t('messages.credentialError'));
        }
      } catch (error) {
        setSubmitting(false);
      }
    };

    const toggleForgotShow = () => {
      setIsForgotPassword((prev) => !prev);
    };

    const handleForgotPasswordSubmit = (email: string) => {
      toolkitDispatch(resetPassword(email));
    };

  const handleGoogleSuccess = (response: CredentialResponse) => {
    console.log("Google SSO Success:", response);
    //getToken(response, 'google');
  };

  const handleGoogleError = () => {
    console.error("Google SSO Failed");
    toast.error(t('auth.googleLoginError'));
  };

  return (
    <>
    <Row className='home justify-content-center'>
      <div className='login h-100 w-100 d-flex justify-content-center align-items-center' style={backgroundImage}>
        <Col xs={11} lg={4} className='tablet-hidden'>
          {/* haz match goes here */}
          &nbsp;
        </Col>
        <Col xs={10} lg={6}>
          <div className='login-outer'>
            <div className='login-inner'>
              <div className={`main${isForgotPassword ? ' w-100' : ''}`}>
                  {!isForgotPassword && (
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={(values, { setSubmitting }) => {
                        handleSubmit(values, setSubmitting);
                      }}
                    >
                      {({ values, isSubmitting, isValid }) => (
                        <Form className='form'>
                          <div className='home-logo'>
                            <Image src={ASSETS_URL + logo} alt='logo Image' fluid />
                          </div>
                          <div className='home-lang-select'>
                            <LanguageSelector />
                          </div>
                          <Row className='mt-2 text-start'>
                            <RbForm.Group
                              as={Col}
                              xs={12}
                              controlId='validationCustom01'
                            >
                              <RbForm.Label className='input-label'>
                                {t('loginForm.emailLabel')}
                              </RbForm.Label>
                              <InputGroup>
                                <Field
                                  className='form-control auth-input'
                                  type='text'
                                  placeholder={t('loginForm.emailPlaceholder')}
                                  aria-describedby='inputGroupPrepend'
                                  name='email'
                                />
                              </InputGroup>
                              <ErrorMessage
                                name='email'
                                component='p'
                                className='text-danger position-absolute error-message'
                              />
                            </RbForm.Group>
                            <RbForm.Group
                              as={Col}
                              xs={12}
                              controlId='validationCustom02'
                            >
                              <RbForm.Label className='input-label'>
                                {t('loginForm.passwordLabel')}
                              </RbForm.Label>
                              <InputGroup hasValidation>
                                <Field
                                  className='form-control auth-input'
                                  type={showPassword ? 'text' : 'password'}
                                  placeholder={t('loginForm.passwordPlaceholder')}
                                  name='password'
                                  autoComplete='on'
                                />
                                <EyeIcon
                                  isOpen={showPassword}
                                  action={() => setShowPassword((prev) => !prev)}
                                />
                              </InputGroup>
                              <ErrorMessage
                                name='password'
                                component='p'
                                className='text-danger position-absolute error-message'
                              />
                            </RbForm.Group>
                          </Row>
                          <Button
                            disabled={!isValid}
                            className='w-100 primary-button p-3'
                            type='submit'
                          >
                            {t('auth.loginButton')}
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  )}

                 {!isForgotPassword && (
                  <div className='justify-content-center'>
                    <SocialIcons/>
                  </div>
                 )}

                  {isForgotPassword && (
                    <ForgotPasswordForm
                      handleSubmit={handleForgotPasswordSubmit}
                      changeShowState={toggleForgotShow}
                    />
                  )}

                    <div
                      className='cursor-pointer forgotpassword'
                      onClick={() => setIsForgotPassword((prev) => !prev)}
                    >
                      {t('loginForm.passwordReset')}
                    </div>

                    <div className='cursor-pointer bold' onClick={() => navigate('/auth/company/sign-up')}>
                      {t('loginForm.registerCompany')}
                    </div>
                    <div className='cursor-pointer bold' onClick={() => navigate('/auth/candidate/sign-up')}>
                      {t('loginForm.registerCandidate')}
                    </div>

                </div>
            </div>
          </div>
        </Col>
      </div>
    </Row>
    </>
  );
};

export default HomeComponent;
