import React, { useState } from 'react';
import Pagination from "../../pagination/Pagination";

interface TabsProps {
  children: React.ReactNode[];
  tabTitles: string[];
  pageHandlers: ((page:number) => void)[];
  pageNumbers: number[];
  pageTotals: number[];
  counts: number[];
}

const Tabs: React.FC<TabsProps> = ({ children, tabTitles, pageHandlers , pageNumbers, pageTotals, counts }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div>
      <ul className="tab-list">
        {tabTitles.map((title, index) => (
          <li
            key={index}
            className={`tab-item ${index === activeTab ? 'active' : ''}`}
            onClick={() => setActiveTab(index)}
            style={{ cursor: 'pointer', padding: '10px', display: 'inline-block' }}
          >
            {title}
          </li>
        ))}
      </ul>
      <div className="tab-content">{children[activeTab]}
        <Pagination
            totalPages={pageTotals[activeTab]}
            currentPage={pageNumbers[activeTab]}
            pageSize={50}
            withAmountSelection={false}
            withTitle={false}
            setAmount={value => 1}
            changeCurrentPage={pageHandlers[activeTab]}
            total={counts[activeTab]}
          />
      </div>
    </div>
  );
};

export default Tabs;
