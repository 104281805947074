import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import Tabs from '../../../components/ui/tabs/Tabs';
import Table from '../../../components/ui/tab-content/TabContent';
import { getCompanies } from "../../../store/slices/companySlice";
import { getCandidates } from "../../../store/slices/candidateSlice";
import { getApplications, getOffers } from "../../../store/slices/vacanciesSlice";
import styles from "../../company/home/HomeCompany.module.scss";
import './HomeAdmin.scss';

const HomeAdmin = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [companyPageNumber, setCompanyPageNumber] = useState<number>(1);
  const [candidatePageNumber, setCandidatePageNumber] = useState<number>(1);
  const [offerPageNumber, setOfferPageNumber] = useState<number>(1);
  const [applicationPageNumber, setApplicationPageNumber] = useState<number>(1);

  const companyCount = useSelector(
    (state: RootState) => state.companyReducer.companyCount
  );
  const candidateCount = useSelector(
    (state: RootState) => state.candidateReducer.candidateCount
  );
  const offerCount = useSelector(
    (state: RootState) => state.vacanciesReducer.offerCount
  );
  const applicationCount = useSelector(
    (state: RootState) => state.vacanciesReducer.applicationCount
  );

function onCompaniesPageChange(page: number): void {
  setCompanyLoading(true);
  dispatch(
    getCompanies({
      pageNumber: page,
      searchValue: '',
      size: 50,
      orderBy: '-created_at',
    })
  ).finally(() => setCompanyLoading(false));;
  setCompanyPageNumber(page);
}

function onCandidatesPageChange(page: number): void {
  setCandidateLoading(true);
  dispatch(
    getCandidates({
      pageNumber: page,
      searchValue: '',
      size: 50,
      orderBy: '-created_at',
    })
  ).finally(() => setCandidateLoading(false));
  setCandidatePageNumber(page);
}

function onOffersPageChange(page: number): void {
  setOfferLoading(true);
  dispatch(
    getOffers({
      pageNumber: page,
      searchValue: '',
      size: 50,
      orderBy: '-created_at',
    })
  ).finally(() => setOfferLoading(false));
  setOfferPageNumber(page);
}

function onApplicationsPageChange(page: number): void {
  dispatch(
    getApplications({
      pageNumber: page,
      searchValue: '',
      size: 50,
      orderBy: '-created_at',
    })
  );
  setApplicationPageNumber(page);
}

  const companyPageTotal = useSelector(
    (state: RootState) => state.companyReducer.pageTotal
  );

  const candidatePageTotal = useSelector(
    (state: RootState) => state.candidateReducer.pageTotal
  );

  const offerPageTotal = useSelector(
    (state: RootState) => state.vacanciesReducer.pageTotal
  );

  // todo - replace when application endpoint created
  const applicationPageTotal= 1;

  // Sorting state for each tab
  const [companySort, setCompanySort] = useState<{ column: string, order: string }>({ column: 'created_at', order: 'desc' });
  const [candidateSort, setCandidateSort] = useState<{ column: string, order: string }>({ column: 'created_at', order: 'desc' });
  const [offerSort, setOfferSort] = useState<{ column: string, order: string }>({ column: 'created_at', order: 'desc' });

  // Loading states
  const [companyLoading, setCompanyLoading] = useState(false);
  const [candidateLoading, setCandidateLoading] = useState(false);
  const [offerLoading, setOfferLoading] = useState(false);

  const adminCompanies = useSelector((state: RootState) => state.companyReducer.adminCompanies);
  const adminCandidates = useSelector((state: RootState) => state.candidateReducer.adminCandidates);
  const adminOffers = useSelector((state: RootState) => state.vacanciesReducer.adminOffers);

  useEffect(() => {
      setCompanyLoading(true);
      dispatch(getCompanies({ pageNumber: 1, searchValue: '', size: 50, orderBy: `${companySort.order === 'asc' ? '' : '-'}${companySort.column}` }))
        .finally(() => setCompanyLoading(false));
    }, [companySort]);

    useEffect(() => {
      setCandidateLoading(true);
      dispatch(getCandidates({ pageNumber: 1, searchValue: '', size: 50, orderBy: `${candidateSort.order === 'asc' ? '' : '-'}${candidateSort.column}` }))
        .finally(() => setCandidateLoading(false));
    }, [candidateSort]);

    useEffect(() => {
      setOfferLoading(true);
      dispatch(getOffers({ pageNumber: 1, searchValue: '', size: 50, orderBy: `${offerSort.order === 'asc' ? '' : '-'}${offerSort.column}` }))
        .finally(() => setOfferLoading(false));
    }, [offerSort]);

  const columnSortKeys: Array<Record<string, string>> = [
    { Name: 'name', NIF: 'nif', Created: 'created_at', Sector: 'sector' }, // Companies
    { NIF: 'nif', Name: 'first_name', Surname: 'last_name', Created: 'created_at', Experience: 'experience' }, // Candidates
    { Company: 'company', Position: 'name', Created: 'created_at', Applications: 'apply_count', Status: 'status' } // Offers
  ];

  const handleSort = (tabIndex: number, column: string) => {
    const sortKey = columnSortKeys[tabIndex][column] ?? column;

    if (tabIndex === 0) {
      setCompanySort(prev => ({ column: sortKey, order: prev.order === 'asc' ? 'desc' : 'asc' }));
    } else if (tabIndex === 1) {
      setCandidateSort(prev => ({ column: sortKey, order: prev.order === 'asc' ? 'desc' : 'asc' }));
    } else if (tabIndex === 2) {
      setOfferSort(prev => ({ column: sortKey, order: prev.order === 'asc' ? 'desc' : 'asc' }));
    }
  };

  const companyData = companyLoading ? [] : adminCompanies.map(item => [item.business_name, item.nif, item.created_at, item.sector]);
  const candidateData = candidateLoading ? [] : adminCandidates.map(item => [item.nif, item.first_name, item.last_name, item.created_at, item.experience[0]?.job_position || "N/A"]);
  const offerData = offerLoading ? [] : adminOffers.map(item => [item.company.name, item.position, item.created_at, item.apply_count.toString(), item.status]);

  return (
    <>
      <Row>
        <Col lg={9}>
          <div className='zestur-card'>
            <div className='zestur-card__header'>
              <h4>Zas!Jobs Administration</h4>
            </div>
          </div>
          <div className='zestur-card__body'>
            <Row className={styles.tabletGap}>
              <Col lg={3}>
                <div className='card-fixed'>
                  Companies : {companyCount}
                </div>
              </Col>
              <Col lg={3}>
                <div className='card-fixed'>
                  Candidates : {candidateCount}
                </div>
              </Col>
              <Col lg={3}>
                <div className='card-fixed'>
                  Offers : {offerCount}
                </div>
              </Col>
              {/*<Col lg={3}>
                <div className='card-fixed'>
                  Applications : {applicationCount}
                </div>
              </Col>*/}
            </Row>
          </div>
        </Col>
      </Row>
    <Row>
      <Col>
        <Tabs tabTitles={['Companies', 'Candidates', 'Offers']}
          pageHandlers={[onCompaniesPageChange, onCandidatesPageChange, onOffersPageChange, onApplicationsPageChange]}
          pageNumbers={[companyPageNumber, candidatePageNumber, offerPageNumber, applicationPageNumber]}
          pageTotals={[companyPageTotal, candidatePageTotal, offerPageTotal, applicationPageTotal]}
          counts={[companyCount, candidateCount, offerCount, applicationCount]}
        >
          <Table
            headings={['Name', 'NIF', 'Created', 'Sector']}
            data={companyData}
            sortState={companySort}
            onSort={(col: string) => handleSort(0, col)}
            sortKeyMap={columnSortKeys[0]}
            loading={companyLoading}
          />
          <Table
            headings={['NIF', 'Name', 'Surname', 'Created', 'Experience']}
            data={candidateData}
            sortState={candidateSort}
            onSort={(col: string) => handleSort(1, col)}
            sortKeyMap={columnSortKeys[1]}
            loading={candidateLoading}
          />
          <Table
            headings={['Company', 'Position', 'Created', 'Applications', 'Status']}
            data={offerData}
            sortState={offerSort}
            onSort={(col: string) => handleSort(2, col)}
            sortKeyMap={columnSortKeys[2]}
            loading={offerLoading}
          />
        </Tabs>
      </Col>
    </Row>
  </>
  );
};

export default HomeAdmin;
