import React, { useEffect } from 'react';
import { Image, Modal, ListGroup, Button } from 'react-bootstrap';
import logo from '../../../assets/images/job-portal/zestur-logo-internal.svg';
import { ASSETS_URL } from '../../../constants';
import Step from '../../../models/step.interface';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { login } from '../../../actions/auth';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function SubmittedForm(props: Step) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const registrationError = useSelector(
    (state: RootState) => state.companyReducer.registrationError
  );
  const companyCred = useSelector((state: RootState) => {
    return {
      email: state.companyReducer.email,
      password: state.companyReducer.password,
    };
  });
  const advantages: string[] = t('companyRegistration.submitModal.advantages', {
    returnObjects: true,
  });

  useEffect(() => {
    if (!registrationError) {
      dispatch(login(companyCred.email, companyCred.password, false, true));
      toast.info(t('messages.emailVerify'));
    } else {
      props.handlePrev();
      toast.error(registrationError);
    }
  }, []);

  return (
    <div className='d-flex align-items-center max-height justify-content-center'>
      <Modal.Dialog>
        <Modal.Body className='modalBox'>
          <Image src={ASSETS_URL + logo} alt='logo Image' fluid />
          <h6 className='text-center'>
            {t('companyRegistration.submitModal.title')}
          </h6>
          <p className='text-center w-50'>
            {t('companyRegistration.submitModal.description')}
          </p>
          <ListGroup as='ul'>
            {advantages.length > 0 &&
              advantages.map((item) => (
                <ListGroup.Item as='li' key={item}>
                  <span className='me-3'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='15'
                      height='15'
                      viewBox='0 0 15 15'
                      fill='none'
                    >
                      <path
                        d='M12.5 3.75L5.625 10.625L2.5 7.5'
                        stroke='#0C85D0'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </span>
                  {item}
                </ListGroup.Item>
              ))}
          </ListGroup>

          <Button
            type='submit'
            onClick={() => navigate('/')}
          >
            {t('registrationForm.steps.actions.finishButton')}
          </Button>
        </Modal.Body>
      </Modal.Dialog>
    </div>
  );
}

export default SubmittedForm;
