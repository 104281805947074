import React from 'react';
import LanguageSelector from '../../language-selector/LanguageSelector';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { useTranslation } from 'react-i18next';
import { HeaderIconAction } from '../../../models/header-action-icon.interface';
import bell from '../../../assets/icons/bell.svg';
import chat from '../../../assets/icons/message-square (1).svg';
import chatread from '../../../assets/icons/message-square.svg';
import chatnone from '../../../assets/icons/message.svg';
import { useNavigate } from 'react-router-dom';
import { toggleNotificationMenuState } from '../../../store/slices/notificationSlice';
import menu from '../../../assets/icons/menu.svg';
import HeaderActionIcon from './HeaderActionIcon';
import { ASSETS_URL } from '../../../constants';
import { Image } from 'react-bootstrap';
import candidateImage from '../../../assets/icons/avatar-placeholder.svg';
import logo from '../../../assets/images/job-portal/zestur-logo-internal.svg';
import { resetCandidateData } from '../../../store/slices/candidateSlice';
import { resetCompanyData } from '../../../store/slices/companySlice';
import { resetLicense } from '../../../store/slices/licenseSlice';

interface Props {
  setIsMenu: (val: boolean) => void;
}

const CandidateHeader = ({ setIsMenu }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const candidateAvatar = useSelector(
    (state: RootState) => state.candidateReducer.avatar
  );

  function logout(redirectUrl: string): void {
    localStorage.removeItem('user');
    dispatch(resetCandidateData());
    dispatch(resetCompanyData());
    dispatch(resetLicense());
    navigate(redirectUrl);
  }

  const candidateActions: HeaderIconAction[] = [
    {
      action: () => dispatch(toggleNotificationMenuState()),
      icon: bell,
      alt: 'notification',
    },
    {
      action: () => navigate('/candidate/chat'),
      icon: chatnone,
      className: 'zestur-header__links-item',
      alt: 'chat - no unread messages',
    },
    {
      action: () => navigate('/candidate/applicants/detail'),
      icon: candidateAvatar || candidateImage,
      className: 'header-profile-desktop object-fit-cover',
      alt: 'User Image',
    },
  ];

  const candidateMobileActions: HeaderIconAction[] = [
    {
      action: () => navigate('/candidate/applicants/detail'),
      icon: candidateAvatar || candidateImage,
      className: 'header-profile object-fit-cover',
      alt: 'User Image',
    },
    {
      action: () => navigate('/candidate'),
      icon: logo,
      className: 'header-logo',
      alt: 'logo Image',
    },
    {
      action: () => setIsMenu(true),
      icon: menu,
      className: 'header-menu white-filter cursor-pointer',
      alt: 'menu Image',
    },
  ];

  return (
    <>
      <div className='zestur-header mobile-hidden justify-content-between'>
        <Image
          src={ASSETS_URL + logo}
          className='header-logo cursor-pointer'
          alt='logo Image'
          onClick={() => navigate('/candidate')}
        />
        <div className='zestur-header__links'>
          <div className='zestur-header__text-links'>
            <div onClick={() => navigate('/candidate')}>
              <span>{t('candidateHeaderLinks.candidate')}</span>
            </div>

            <div onClick={() => navigate('/candidate/request')}>
              <span>{t('candidateHeaderLinks.request')}</span>
            </div>

            <div onClick={() => logout('/')}>
              <span>{t('candidateHeaderLinks.logout')}</span>
            </div>
          </div>

          <div className='zestur-header__icons align-items-center'>
            <LanguageSelector />

            {candidateActions.map((item, index) => (
              <HeaderActionIcon key={index} item={item} />
            ))}
          </div>
        </div>
      </div>

      <div className={`zestur-header-mobile no-mobile-hidden`}>
        {candidateMobileActions.map((item, index) => (
          <Image
            key={index}
            src={ASSETS_URL + item.icon}
            className={item.className}
            alt={item.alt}
            onClick={item.action}
          />
        ))}
      </div>
    </>
  );
};

export default CandidateHeader;
