import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import SelectLicenseForm from '../select-license-form/SelectLicenseForm';
import { Button, Modal, Row } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  setLicense,
  updateCandidate,
} from '../../../store/slices/candidateSlice';
import {
  setIsVehicle,
  updateLicenseListSelection,
} from '../../../store/slices/licenseSlice';

interface Props {
  show: boolean;
  handleClose: () => void;
}

const CandidateLicenseModal = ({ show, handleClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const candidate = useSelector((state: RootState) => state.candidateReducer);
  const licenseList = useSelector(
    (state: RootState) => state.licenseReducer.licenseList
  );
  const isVehicle = useSelector(
    (state: RootState) => state.licenseReducer.isVehicle
  );

  useEffect(() => {
    if (candidate.license.license?.length) {
      dispatch(updateLicenseListSelection(candidate.license.license));
    }
    dispatch(setIsVehicle(candidate.license.isVehicle));
  }, [candidate.license]);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('vacancyDetails.subtitles.license')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{}}
          onSubmit={(_) => {
            dispatch(
              setLicense({
                license: licenseList
                  .slice(1)
                  .filter((license) => license.selected),
                isVehicle: isVehicle,
              })
            );

            dispatch(updateCandidate());
            handleClose();
          }}
        >
          {() => (
            <FormikForm className='form w-100 d-flex justify-content-evenly flex-column'>
              <Row className='language-form'>
                <SelectLicenseForm
                  licenseList={licenseList}
                  isVehicle={isVehicle}
                />
              </Row>
              <Button className='submitbtn sans p-3' type='submit'>
                {t('submitButton')}
              </Button>
            </FormikForm>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default CandidateLicenseModal;
