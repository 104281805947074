import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../store/store';
import { verificateEmail } from '../../store/slices/emailVerificationSlice';

const EmailActivation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const urlArray = location.pathname.split('/');

  useEffect(() => {
    dispatch(
      verificateEmail({
        uid: urlArray.at(-2) || '',
        token: urlArray.at(-1) || '',
      })
    ).then((res) => {
      if (res.payload?.user_type === 'Candidate') {
        navigate('/');
      } else {
        navigate('/');
      }
    });
  }, []);

  return (
    <div className='table-loader vh-100'>
      <div className='spinner'>
        {Array(10)
          .fill(null)
          .map((_, index) => (
            <div key={index}></div>
          ))}
      </div>
    </div>
  );
};

export default EmailActivation;
