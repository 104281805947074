import { ASSETS_URL } from '../constants';
import bgImg from '../assets/images/job-portal/receptionist.png';
import bgCandidate from '../assets/images/job-portal/aspirantes.png';
import bgCompany from '../assets/images/job-portal/empresas.png';

export const backgroundImage = {
  backgroundImage: `url('${ASSETS_URL + bgImg}')`,
  backgroundSize: 'cover',
};

export const candidateBg = {
  backgroundImage: `url('${ASSETS_URL + bgCandidate}')`,
  backgroundSize: 'cover',
};

export const companyBg = {
  backgroundImage: `url('${ASSETS_URL + bgCompany}')`,
  backgroundSize: 'cover',
};