import React from 'react';
import home from '../../../assets/icons/home.svg';
import layers from '../../../assets/icons/layers.svg';
import logout from '../../../assets/icons/logout.svg';
import message from '../../../assets/icons/message.svg';
import notification from '../../../assets/icons/notification.svg';
import close from '../../../assets/icons/close.svg';
import blog from '../../../assets/icons/blog.svg';
import { useNavigate } from 'react-router-dom';
import './Menu.scss';
import MenuLink from '../../ui/menu-link/MenuLink';
import { ASSETS_URL } from '../../../constants';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../../language-selector/LanguageSelector';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store/store';
import { resetCandidateData } from '../../../store/slices/candidateSlice';
import { resetCompanyData } from '../../../store/slices/companySlice';
import { resetLicense } from '../../../store/slices/licenseSlice';
import { toggleNotificationMenuState } from '../../../store/slices/notificationSlice';

interface Props {
  closeMenu: () => void;
  isCompany: boolean;
}

const Menu = ({ closeMenu, isCompany }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  function logoutUser(): void {
    localStorage.removeItem('user');
    dispatch(resetCandidateData());
    dispatch(resetLicense());
    dispatch(resetCompanyData());
  }

  const links = [
    {
      to: isCompany ? '/company' : '/candidate',
      icon: home,
      text: isCompany ? t('menu.company.home') : t('menu.candidate.home'),
    },
    {
      to: ' ',
      onAction: () => dispatch(toggleNotificationMenuState()),
      icon: notification,
      text: t('menu.notifications'),
    },
    {
      to: isCompany ? '/company/applicants' : '/candidate/request',
      icon: layers,
      text: isCompany ? t('menu.company.info') : t('menu.candidate.info'),
    },
    {
      to: isCompany ? '/company/chat' : '/candidate/chat',
      icon: message,
      text: t('menu.messages'),
    },
    {
      to: isCompany ? '/company/vacancies' : '/candidate/blogs',
      icon: blog,
      text: isCompany ? t('menu.company.blog') : '',
    },
  ];

  return (
    <div className='menu'>
      <div className='menu__close'>
        <LanguageSelector />
        <img
          className='blue-filter menu__close-icon'
          src={ASSETS_URL + close}
          alt='close'
          onClick={closeMenu}
        />
      </div>
      <div className='zestur-sidebar__links'>
        {links.map((link, index: number) => {
          if (!link.text) return;

          return (
            <MenuLink
              key={index}
              to={link.to}
              icon={link.icon}
              text={link.text}
              onAction={link.onAction}
              closeMenu={closeMenu}
            />
          );
        })}

        {isCompany && (
          <div
            className='d-flex align-items-center justify-content-center'
            onClick={closeMenu}
          >
            <button
              type='button'
              onClick={() => {
                navigate('/company/offers');
              }}
              className='btn px-4 btn-outline-primary btn-outline-color'
            >
              {t('menu.company.publishButton')}
            </button>
          </div>
        )}

        <MenuLink
          to={'/'}
          icon={logout}
          text={t('menu.logout')}
          closeMenu={closeMenu}
          onAction={logoutUser}
        />
      </div>
    </div>
  );
};

export default Menu;
