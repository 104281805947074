import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import Experience from '../../models/experience.interface';
import Education from '../../models/education.interface';
import User from '../../models/user.interface';
import Language from '../../models/language.interface';
import ContactInfo from '../../models/contact-info.interface';
import CandidateTag from '../../models/candidate-tag.interface';
import {RootState} from '../store';
import Option from '../../models/option.interface';
import tokenService from '../../_services/token.service';
import axios from 'axios';
import {config} from '../../_config/config';
import {Resume} from '../../models/resume.interface';
import Certificate from '../../models/certificate.interface';
import Vacancy from '../../models/vacancy.interface';
import {License} from '../../models/license.interface';
import {decodeToken} from '../../models/decode-token';
import {getLocalSkillListFromAI} from '../../utils/get-local-skill-from-ai';
import LanguageOption from '../../models/language-option.interface';
import {getLanguagesFromAiResult} from '../../utils/ai-resume-post-proccess/get-lang-list-from-ai-result';
import {getEducationFromAiResult} from '../../utils/ai-resume-post-proccess/get-education-from-ai-result';
import {getExperienceFromAiResult} from '../../utils/ai-resume-post-proccess/get-experience-from-ai-result';
import {AdminCandidate} from "../../models/admin-candidate.interface";
import VacancyForm from "../../models/vacancy-form.interface";

interface SavedOffer {
  id: string;
  offer: string;
  user: string;
}

interface CandidateState {
  candidate: User;
  avatar: string;
  address: string;
  description: string;
  role: string;
  seniorityLevel: string;
  uploadedResume: { file: File }[];
  cv: Resume[];
  uploadedCVName: string;
  contactInfo: ContactInfo;
  experience: Experience[];
  license: License;
  certificates: Certificate[];
  education: Education[];
  languages: Language[];
  skills: CandidateTag[];
  hardSkills: CandidateTag[];
  positions: CandidateTag[];
  locations: CandidateTag[];
  registrationError?: string | null;
  tokenExhausted: boolean;
  resumeAnalysing: boolean;
  candidateDoNotExists: boolean;
  requestedVacancies: Vacancy[];
  requestedVacanciesSearch: string;
  is_verified: boolean;

  savedOffers: SavedOffer[];
  savedOffersReceived: boolean;

  candidateCount: number;
  adminCandidates: AdminCandidate[];
  pageTotal: number;
}

const initialState: CandidateState = {
  candidate: {
    userId: '',
    email: '',
    password: '',
  },
  address: '',
  description: '',
  role: '',
  seniorityLevel: '',
  avatar: '',

  contactInfo: {
    name: '',
    lastName: '',
    country: '',
    city: '',
    numberCode: '+34',
    phoneNumber: '',
    phone: '',
    birthDate: '',
    nif: '',
  },

  license: {
    license: [],
    isVehicle: false,
  },
  certificates: [],
  experience: [],
  education: [],
  languages: [],
  skills: [],
  hardSkills: [],
  uploadedResume: [],
  cv: [],
  uploadedCVName: '',
  positions: [],
  locations: [],
  requestedVacancies: [],
  requestedVacanciesSearch: '',

  registrationError: null,
  tokenExhausted: false,
  candidateDoNotExists: false,
  resumeAnalysing: false,
  is_verified: false,

  savedOffers: [],
  savedOffersReceived: true,

  candidateCount: 0,
  adminCandidates: [],
  pageTotal: 1,
};

export const getCandidate = createAsyncThunk(
  'candidate/getCandidate',
  async () => {
    const token = tokenService.getLocalAccessToken();
    const decodedToken = decodeToken(token);

    const response = await axios.get(
      `${config.SERVER_URL}candidate/${decodedToken?.user_id}/`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    return response.data.body;
  }
);

export const createCandidate = createAsyncThunk(
  'candidate/createCandidate',
  async (_, { getState }) => {
    const state: RootState = getState() as RootState;
    const candidateState = state.candidateReducer;
    let currentLanguage = localStorage.getItem('lang');
    if (!currentLanguage || currentLanguage == 'sp') {
      currentLanguage = 'es';
    } else if (currentLanguage == 'pt') {
      currentLanguage = 'pr';
    }

    const payload = {
      password: candidateState.candidate.password,
      email: candidateState.candidate.email,
      first_name: candidateState.contactInfo.name,
      last_name: candidateState.contactInfo.lastName,
      nif: candidateState.contactInfo.nif,
      dob: candidateState.contactInfo.birthDate,
      country: candidateState.contactInfo.country,
      city: candidateState.contactInfo.city,
      phone:
        candidateState.contactInfo.numberCode +
        candidateState.contactInfo.phoneNumber,
      experience: candidateState.experience,
      education: candidateState.education,
      skill: candidateState.skills.map((item) => {
        return {
          name: item.name || item.id,
          description: item.description || '',
        };
      }),
      hard_skills: candidateState.hardSkills.map((item) => {
        return {
          name: item.id || item.name,
          description: item.description || '',
        };
      }),
      language: candidateState.languages,
      certificates: [],
      license: candidateState.license.license.map(
        (item: Option) => item.name || item
      ),
      is_vehicle: candidateState.license.isVehicle,
    };

    let url = config.SERVER_URL + 'candidate/?current_language=' + currentLanguage;
    const storageCompany = sessionStorage.getItem('searchedCompany');
    if (storageCompany) {
        url += '&company=' + storageCompany;
    }

    const response = await axios.post(
      url,
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;
  }
);

export const updateCandidate = createAsyncThunk(
  'candidate/updateCandidate',
  async (_, { getState }) => {
    const token = tokenService.getLocalAccessToken();
    const state: RootState = getState() as RootState;
    const candidateState = state.candidateReducer;

    const payload: any = {
      certificates: candidateState.certificates,
      role: candidateState.role || null,
      seniority: candidateState.seniorityLevel || null,
      address: candidateState.address || null,
      email: candidateState.candidate.email,
      first_name: candidateState.contactInfo.name,
      last_name: candidateState.contactInfo.lastName,
      nif: candidateState.contactInfo.nif,
      dob: candidateState.contactInfo.birthDate || '1900-01-01',
      phone:
        candidateState.contactInfo.numberCode +
        candidateState.contactInfo.phoneNumber,
      experience: candidateState.experience,
      education: candidateState.education,

      skill: candidateState.skills.map((item) => {
        return {
          name: item.id?.length! > 12 ? item.name : item.id,
          description: item.description || '',
        };
      }),
      hard_skills: candidateState.hardSkills.map((item) => {
        return {
          name: item.id || item.name,
          description: item.description || '',
        };
      }),
      language: candidateState.languages,
      city: candidateState.contactInfo.city,
      country: candidateState.contactInfo.country,
      license: candidateState.license.license.map(
        (item: Option) => item.name || item
      ),
      is_vehicle: candidateState.license.isVehicle,
    };

    if (candidateState.description) {
      payload.description = candidateState.description;
    }

    const response = await axios.patch(
      config.SERVER_URL + 'candidate/' + candidateState.candidate.userId + '/',
      payload,
      {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data.body;
  }
);

export const uploadResume = createAsyncThunk(
  'candidate/uploadResume',
  async (_, { getState }) => {
    const token = tokenService.getLocalAccessToken();
    const decodedToken = decodeToken(token);
    const state: RootState = getState() as RootState;

    const response = await axios.post(
      config.SERVER_URL + 'candidate/cv/' + decodedToken?.user_id + '/',
      state.candidateReducer.uploadedResume[0],
      {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return response.data;
  }
);

export const uploadAvatar = createAsyncThunk(
  'candidate/uploadAvatar',
  async (avatar: File) => {
    const token = tokenService.getLocalAccessToken();
    const decodedToken = decodeToken(token);

    const ava = new FormData();
    ava.append('file', avatar);

    const response = await axios.put(
      config.SERVER_URL + 'candidate/avatar/' + decodedToken?.user_id + '/',
      { avatar: avatar },
      {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return response.data;
  }
);

export const getSavedOffers = createAsyncThunk(
  'candidate/getSavedOffers',
  async () => {
    const token = tokenService.getLocalAccessToken();

    const response = await axios.get(config.SERVER_URL + 'offer/saved/list', {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });

    return response.data;
  }
);

export const offerApply = createAsyncThunk(
  'candidate/offerApply',
  async (offerId: string) => {
    const token = tokenService.getLocalAccessToken();

    const response = await axios.post(
      config.SERVER_URL + 'offer/apply/',
      { offer: offerId },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    return response.data;
  }
);

export const offerUnApply = createAsyncThunk(
  'candidate/offerUnApply',
  async (offerId: string) => {
    const token = tokenService.getLocalAccessToken();

    const response = await axios.delete(
      config.SERVER_URL + 'offer/apply/delete/' + offerId + '/',
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    return response.data;
  }
);

export const requestedOffersList = createAsyncThunk(
  'candidate/requestedOffersList',
  async () => {
    const token = tokenService.getLocalAccessToken();

    const response = await axios.get(
      config.SERVER_URL + 'offer/candidate/apply/list?size=100',
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    return response.data.data;
  }
);

export const saveOffer = createAsyncThunk(
  'candidate/saveOffer',
  async (offerId: string) => {
    const token = tokenService.getLocalAccessToken();
    const decodedToken = decodeToken(token);

    const response = await axios.post(
      config.SERVER_URL + 'offer/saved/create/',
      {
        offer: offerId,
        user: decodedToken?.user_id,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    return response.data;
  }
);

export const deleteOffer = createAsyncThunk(
  'candidate/deleteOffer',
  async (offerId: string) => {
    const token = tokenService.getLocalAccessToken();

    await axios.delete(
      config.SERVER_URL + 'offer/saved/delete/' + offerId + '/',
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    return offerId;
  }
);

export const setExperienceLogo = createAsyncThunk(
  'candidate/setExperience',
  async ({ image, experienceId }: { image: File; experienceId: string }) => {
    const token = tokenService.getLocalAccessToken();

    const response = await axios.post(
      config.SERVER_URL +
        'candidate/experience/' +
        experienceId +
        '/company/logo/',
      { company_logo: image },
      {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return { logo: response.data.body.company_logo, experienceId };
  }
);

export const sendResumeToAI = createAsyncThunk(
  'candidate/sendResumeToAI',
  async (file: File, { getState }) => {
    const state: RootState = getState() as RootState;
    const formData = new FormData();

    formData.append('user_id', '1');
    formData.append('value1', '1');
    formData.append('file', file);

    const response = await axios.post(config.APP_URL + '/upload/', formData);

    return { data: response.data, languages: state.languageReducer.languages };
  }
);

export const checkNif = createAsyncThunk(
  'candidate/checkNif',
  async (nif: string) => {
    const token = tokenService.getLocalAccessToken();
    try {
        const response = await axios.post(
            config.SERVER_URL + 'candidate/check-nif/',
            {nif: nif},
        );
        // If there's no error, the candidate doesn't exist yet
        return true;
    } catch (error) {
      // Candidate NIF exists
      return false;
    }
  }
);


export const applyToQROffer = createAsyncThunk(
  'offer/qrApply',
  async (company:string) => {
    const token = tokenService.getLocalAccessToken();
    const decodedToken = decodeToken(token);
    const candidate_id = decodedToken?.user_id || "";

    const response = await axios.post(
    `${config.SERVER_URL}offer/qrcode_candidate/`,
    {company: company, candidate_id: candidate_id},
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });

    return response.data.body;
  }
);

export const getCandidates = createAsyncThunk(
  'candidate/getCandidates',
  async ({
    pageNumber,
    searchValue,
    size,
    orderBy = '',
  }: {
    pageNumber: number;
    searchValue: string;
    size: number;
    orderBy?: string;
  }) => {
    const token = tokenService.getLocalAccessToken();

    const response = await axios.get(
      config.SERVER_URL + `candidate` +
       `/?page=${pageNumber}&size=${size}&search=${searchValue}&order_by=${orderBy}`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    return response.data;
  }
);

const candidateSlice = createSlice({
  name: 'candidate',
  initialState,
  reducers: {
    setCandidate: (state, action) => {
      state.candidate = action.payload;
    },

    addResume: (state, action) => {
      state.uploadedResume = [action.payload];
      state.uploadedCVName = action.payload.file.name;
    },

    setContactInfo: (state, action) => {
      state.contactInfo = action.payload;
    },

    setLicense: (state, action) => {
      state.license = action.payload;
    },

    // Experience
    addExperience: (state, action) => {
      state.experience.unshift(action.payload);
    },

    deleteExperience: (state, action) => {
      state.experience = state.experience.filter(
        (_, i) => i !== action.payload
      );
    },

    editExperience: (state, action) => {
      const editedElementIndex = action.payload.editedExperienceIndex;
      state.experience[editedElementIndex] = action.payload.data;
    },

    // Education
    addEducation: (state, action) => {
      state.education.push(action.payload);
    },

    deleteEducation: (state, action) => {
      state.education = state.education.filter((_, i) => i !== action.payload);
    },

    editEducation: (state, action) => {
      const editedElementIndex = action.payload.editedEducationIndex;
      state.education[editedElementIndex] = action.payload.data;
    },

    // Language
    setLanguages: (state, action) => {
      state.languages = action.payload;
    },

    addLanguage: (state, action) => {
      state.languages.unshift(action.payload);
    },

    editLanguage: (state, action) => {
      const editedElementIndex = action.payload.index;
      state.languages[editedElementIndex] = action.payload.language;
    },

    deleteLanguage: (state, action) => {
      state.languages = state.languages.filter((_, i) => i !== action.payload);
    },

    // Skills
    setSkills: (state, action) => {
      state.skills = action.payload;
    },

    addSkills: (state, action) => {
      state.skills = state.skills.concat(action.payload);
    },

    deleteSkill: (state, action) => {
      state.skills = state.skills.filter(
        (skill) => skill.name !== action.payload
      );
    },

    // hardSkills
    setHardSkills: (state, action) => {
      state.hardSkills = action.payload;
    },

    addHardSkills: (state, action) => {
      state.hardSkills = state.hardSkills.concat(action.payload);
    },

    deleteHardSkill: (state, action) => {
      state.hardSkills = state.hardSkills.filter(
        (skill) => skill.name !== action.payload
      );
    },

    // Certificate
    addCertificate: (state, action) => {
      state.certificates.unshift(action.payload);
    },

    editCertificate: (state, action) => {
      const editedElementIndex = action.payload.editedCertificateIndex;
      state.certificates[editedElementIndex] = action.payload.data;
    },

    deleteCertificate: (state, action) => {
      state.certificates = state.certificates.filter(
        (_, i) => i !== action.payload
      );
    },

    // Assigned Vacancies
    setRequestedVacancies: (state, action) => {
      state.requestedVacancies = action.payload;
    },

    addRequestedVacancy: (state, action) => {
      state.requestedVacancies.push(action.payload);
    },

    deleteRequestedVacancy: (state, action) => {
      state.requestedVacancies = state.requestedVacancies.filter(
        (item) => item.id !== action.payload.id
      );
    },

    setAvatar: (state, action) => {
      state.avatar = action.payload;
    },

    setPositions: (state, action) => {
      state.positions = action.payload;
    },

    setLocations: (state, action) => {
      state.locations = action.payload;
    },

    setAddress: (state, action) => {
      state.address = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setSeniorityLevel: (state, action) => {
      state.seniorityLevel = action.payload;
    },
    setCandidateTokenExhausted: (state, action) => {
      state.tokenExhausted = action.payload;
    },
    setCandidateExistsError: (state, action) => {
      state.candidateDoNotExists = action.payload;
    },
    setRequestedVacanciesSearch: (state, action) => {
      state.requestedVacanciesSearch = action.payload;
    },
    setResumeAnalysing: (state, action) => {
      state.resumeAnalysing = action.payload;
    },
    resetCandidateData: () => initialState,
  },
  extraReducers(builder) {
    builder
      // Create candidate
      .addCase(createCandidate.fulfilled, (state, action) => {
        state.registrationError = null;
      })
      .addCase(createCandidate.rejected, (state, action) => {
        state.registrationError = action.error.message;
        console.error(action.error);
      })

      // Get candidate
      .addCase(getCandidate.fulfilled, (state, action) => {
        sessionStorage.removeItem('searchedCompany');
        if (!action.payload) return;
        state.tokenExhausted = false;
        state.candidateDoNotExists = false;

        state.candidate.email = action.payload.email;
        state.candidate.userId = action.payload.id;

        state.contactInfo.name = action.payload.first_name;
        state.contactInfo.lastName = action.payload.last_name;
        state.contactInfo.country = action.payload.country;
        state.contactInfo.city = action.payload.city;
        state.contactInfo.numberCode = action.payload.phone.substring(0, 3);
        state.contactInfo.phoneNumber = action.payload.phone.substring(3);
        state.contactInfo.phone = action.payload.phone;
        state.contactInfo.nif = action.payload.nif;
        state.contactInfo.birthDate = action.payload.dob;

        state.experience = action.payload.experience;
        state.education = action.payload.education;
        state.languages = action.payload.language;
        state.skills = action.payload.skill;
        state.hardSkills = action.payload.hard_skills || [];
        state.cv = action.payload.cv;
        state.license.license = action.payload.license;
        state.license.isVehicle = action.payload.is_vehicle;

        state.avatar = action.payload.avatar;
        state.role = action.payload.role;
        state.address = action.payload.address;
        state.description = action.payload.description;
        state.seniorityLevel = action.payload.seniority;
        state.certificates = action.payload.certificates;
        state.is_verified = action.payload.is_verified;
      })

      .addCase(getCandidate.rejected, (state, action) => {
        if (action.error.message?.includes('403')) {
          state.tokenExhausted = true;
        }
      })

      // Upload resume
      .addCase(uploadResume.fulfilled, (state, action) => {
        state.uploadedResume = [];
        state.cv.push(action.payload);
      })

      // Update candidate
      .addCase(updateCandidate.fulfilled, (state, action) => {
        state.education = action.payload.education;
        state.experience = action.payload.experience;
        state.languages = action.payload.language;
        state.skills = action.payload.skill;
        state.hardSkills = action.payload.hard_skills || [];
        state.certificates = action.payload.certificates;
        state.contactInfo.numberCode = action.payload.phone.substring(0, 3);
        state.contactInfo.phoneNumber = action.payload.phone.substring(3);
        state.contactInfo.phone = action.payload.phone;
        state.contactInfo.city = action.payload.city;
        state.contactInfo.country = action.payload.country;
      })

      // Upload avatar
      .addCase(uploadAvatar.fulfilled, (state, action) => {
        state.avatar = action.payload.body.avatar;
      })

      // Saved Offers
      .addCase(getSavedOffers.fulfilled, (state, action) => {
        state.savedOffersReceived = true;
        state.savedOffers = action.payload;
      })

      .addCase(deleteOffer.fulfilled, (state, action) => {
        state.savedOffers = state.savedOffers.filter(
          (offer) => offer.offer !== action.payload
        );
      })

      .addCase(saveOffer.fulfilled, (state, action) => {
        state.savedOffers.push(action.payload);
      })

      // Experience Logo
      .addCase(setExperienceLogo.fulfilled, (state, action) => {
        const updatedExperienceIndex = state.experience.findIndex(
          (exp) => exp.id === action.payload.experienceId
        );

        state.experience[updatedExperienceIndex].company_logo =
          action.payload.logo;
      })

      .addCase(requestedOffersList.fulfilled, (state, action) => {
        state.requestedVacancies = action.payload.map(
          (item: any) => item.offer
        );
      })

      .addCase(sendResumeToAI.fulfilled, (state, action) => {
        const data = JSON.parse(action.payload.data);
        const languageList: LanguageOption[] = action.payload.languages;
        state.resumeAnalysing = false;

        state.address = data.address;
        state.certificates = data.certificates.filter(
          (item: Certificate) => item.description && item.certificate_code
        );
        state.languages = getLanguagesFromAiResult(data.language, languageList);
        state.description = data.description;
        state.education = getEducationFromAiResult(data.education);
        state.experience = getExperienceFromAiResult(data.experience);
        state.skills = getLocalSkillListFromAI('soft', data.skill);
        state.hardSkills = getLocalSkillListFromAI('hard', data.skill);
      })

      .addCase(sendResumeToAI.rejected, (state, action) => {
        state.resumeAnalysing = false;
      })

    .addCase(getCandidates.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.candidateCount = action.payload.body.totalElements;
        state.adminCandidates = action.payload.body.data;
        state.pageTotal = action.payload.body.totalPages;
      })
    ;
  },
});

export const {
  setCandidate,
  addResume,
  setLicense,
  addExperience,
  deleteExperience,
  editExperience,
  addCertificate,
  editCertificate,
  deleteCertificate,
  setAvatar,
  addEducation,
  deleteEducation,
  editEducation,
  setContactInfo,
  setLanguages,
  deleteLanguage,
  editLanguage,
  addLanguage,
  setSkills,
  addSkills,
  deleteSkill,
  setRequestedVacancies,
  addRequestedVacancy,
  deleteRequestedVacancy,
  setLocations,
  setPositions,
  setCandidateTokenExhausted,
  setCandidateExistsError,
  setAddress,
  setDescription,
  setRole,
  setSeniorityLevel,
  setResumeAnalysing,
  resetCandidateData,
  setRequestedVacanciesSearch,
  setHardSkills,
  addHardSkills,
  deleteHardSkill,
} = candidateSlice.actions;
export default candidateSlice.reducer;
