import React from 'react';
import { Spinner } from 'react-bootstrap';

interface TableProps {
  headings: string[];
  data: string[][];
  onSort: (column: string) => void;
  sortState: { column: string; order: string };
  sortKeyMap: Record<string, string>; // Map display name to actual sort key
  loading: boolean;
}

const Table: React.FC<TableProps> = ({ headings, data, onSort, sortState, sortKeyMap, loading }) => {
  return (
    <table>
      <thead>
        <tr>
          {headings.map((heading, index) => {
            const sortKey = sortKeyMap[heading] ?? heading; // Get actual sort key
            const isSorted = sortState.column === sortKey;
            const arrow = isSorted ? (sortState.order === 'asc' ? ' ▲' : ' ▼') : '';

            return (
              <th key={index} onClick={() => onSort(sortKey)} style={{ cursor: 'pointer' }}>
                {heading}{arrow}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr>
            <td colSpan={headings.length} style={{ textAlign: 'center', padding: '20px' }}>
              <Spinner animation="border" size="sm" /> Loading...
            </td>
          </tr>
        ) : data.length > 0 ? (
          data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex}>{cell}</td>
              ))}
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={headings.length} style={{ textAlign: 'center', padding: '20px' }}>No data available</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default Table;
