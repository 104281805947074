import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import Store from './store';
import store from './store/store';
import setupInterceptors from './_services/setupInterceptors';
import 'bootstrap/dist/css/bootstrap.min.css';
import './i18n/i18n';
import InternetObserver from './components/internet-observer/InternetObserver';
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const AppWrapper = () => {
  React.useEffect(() => {
    setupInterceptors(Store);
  }, []);
  return (
    <GoogleOAuthProvider clientId='172631265102-irtrp8e9kkqm0814viivu12vf6mupvee.apps.googleusercontent.com'>
      <BrowserRouter>
        <InternetObserver />
        <Provider store={Store}>
          <Provider store={store}>
            <App />
          </Provider>
        </Provider>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
};

root.render(<AppWrapper />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
